import self from "../img/self.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(65,109,180)","rgb(70,150,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Roberto",
    lastName: "De Paoli",
    initials: "RDP", // the example uses first and last, but feel free to use three or more if you like.
    position: "in fase di sviluppo",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: "👨🏻‍💻",
            text: "IT Engineering student"
        },
        {
            emoji: '🌎',
            text: 'based in Italy'
        },
        {
            emoji: "📧",
            text: "info@robertodepaoli.com"
        }
    ],
    socials: [
        {
            link: "https://instagram.com/roberto_depaolee?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/robertodepaoli",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/roberto-de-paoli-b6a066242",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://t.me/robertodepaoli",
            icon: 'fa fa-telegram',
            label: 'telegram'
        }
    ],
    bio: "Hello! I'm John. I'm a systems engineer for Google. I studied CompSci at Harvard, I enjoy long walks on the beach, and I believe artificial intelligence will inevitably rule us all one day. You should hire me!",
    hobbies: [
        {
            label: 'reading',
            emoji: '📖'
        },
        {
            label: 'theater',
            emoji: '🎭'
        },
        {
            label: 'movies',
            emoji: '🎥'
        },
        {
            label: 'cooking',
            emoji: '🌶'
        }
    ]
}